<template>
    <div class="my_warp">
        <div class="zbj_box">
            <!-- 左边视频 打赏 -->
            <div class="left_box">
                <div class="top_box">
                    <div style="margin-right: 14px;"><img class="user_img" :src="info.user_avatar" /></div>
                    <div>
                        <div>
                            {{ info.desc }}
                        </div>
                        <div class="dz">
                            {{ info.praise_num }}本场点赞
                        </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <i class="el-icon-location-outline"></i>
                        <span>表演</span>
                    </div>
                </div>
                <!-- 视频播放 -->
                <div class="video_box">
                    <div id="dplayer" ref="Mydplayer"></div>
                    <vue-danmaku :danmus="danmus" class="myDanmus" style="height:540px; width:100%;" ref="danmaku"
                        @play-end="end">
                    </vue-danmaku>
                </div>
                <!-- 打赏 -->
                <div class="reward_list">
                    <div class="gift-container" @mouseover="showCount(index, item.gold)"
                        @mouseleave="() => { showCountText = '', giftsFlag = '' }" v-for="(item, index) in imgList"
                        :key="index">
                        <!-- 图片 -->
                        <div class="img-container" @mouseover="giftsFlag = item.gold" @mouseleave="giftsFlag = ''">
                            <img style="height: 40px;" :src="item.icon" :alt="item.icon" />
                            <div>{{ !(giftsFlag == item.gold) ? `${item.gold}金币` : item.name }}</div>
                        </div>
                        <!-- 赠送数量的窗口 -->
                        <div class="count-window" v-show="showCountText == 'showCountText_' + index">
                            <div class="num_list">
                                <div class="DsSiFD5E" @click="rewardMoney(_item, item.gold, item.icon, item.id)"
                                    @mouseover="showDsSText(_item)" @mouseleave="DsStext = ''" v-for="_item in numList"
                                    :key="_item">
                                    <span v-show="DsStext == 'DsStext' + _item">送</span>
                                    <span>{{ _item }}个</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 右边聊天室 -->
            <div class="right_box">
                <div class="gz">
                    在线观众 · {{ info.live_num }}
                </div>
                <div id="chat-room">
                    <div class="content" ref="content">
                        <div style="color: #EBA825;">
                            本表演间聊天要有礼貌。禁止任何低俗、色情和裸体展示行为。如果发现该行为，帐户将被禁止使用。巡查24小时不间断检查。<br>
                            温馨提示：不允许第三方下载或录当前表演。
                        </div>
                        <div style="padding: 8px 0;" v-for="item in textList">
                            <div v-if="!item.icon">
                                <span style="color: #8ce7ff;">{{ item.nickname }}：</span>
                                <span>{{ item.textarea }}</span>
                            </div>
                            <div v-else>
                                <span style="color: #8ce7ff;">{{ item.nickname }}：</span>
                                <span style="color:#EBA825">送出了</span>
                                <img style="margin: 0 4px;height: 20px;" :src="item.icon" />
                                <span style="color:#EBA825">×&ensp;{{ item.num  }}</span>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex;margin-top: 20px;" v-if="$store.state.user_id">
                        <textarea maxlength="50" show-word-limit="true" class="my-input" placeholder="与大家互动一下..."
                            style="padding-right: 24px; padding-left: 8px; line-height: 20px;"
                            v-model="textarea"></textarea>
                        <el-button @click="pushDanmus">发送</el-button>
                    </div>
                    <div style="display: flex;margin-top: 20px;width: 100%;" v-else>
                        <div @click="concern" style="margin: 0 auto;">需先<el-button type="text"
                                style="color: #fe2c55;">登录</el-button>，才能开始聊天</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 登录注册 -->
        <el-dialog :title="registerFlag ? '登录账号' : '注册账号'" :visible.sync="dialogVisible" width="600px" @closed="openInint"
            :close-on-click-modal="false">
            <el-form label-position="left" label-width="80px" :model="registerForm" ref="registerForm"
                style="width: 350px; margin: 0 auto" :rules="registerRules">
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="registerForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="msg_code">
                    <el-input v-model="registerForm.msg_code">
                        <el-button slot="append" type="text" @click="sendcode" :disabled="!show">
                            获取验证码
                            <span v-show="!show" class="count">({{ count }}s)</span>
                        </el-button>
                    </el-input>
                </el-form-item>

                <el-button @click="Login" style="width: 350px">{{
                    registerFlag ? "立即登录" : "立即注册"
                }}</el-button>
                <div>
                    同意<el-button type="text" @click="goAgreement">用户协议</el-button>及<el-button type="text"
                        @click="goConceal">隐私政策</el-button>
                </div>
            </el-form>
            <el-dialog width="400px" title="请完成下列验证后继续" :visible.sync="innerVisible" append-to-body v-if="innerVisible"
                :close-on-click-modal="false">
                <slide-verify v-loading="slideLoading" element-loading-spinner="el-icon-loading" element-loading-text="正在验证"
                    class="slide" :l="42" :r="10" :w="340" :h="165" @success="onSuccess" @fail="onFail" @refresh="onRefresh"
                    slider-text="按住左边按钮拖动完成上方拼图"></slide-verify>
            </el-dialog>
        </el-dialog>
    </div>
</template>

<script>
import VueDanmaku from 'vue-danmaku'
import { mapState } from 'vuex';
import DPlayer from 'dplayer';
import 'vue-dplayer/dist/vue-dplayer.css'
const TIME_COUNT = 60;
export default {
    components: {
        VueDanmaku
    },
    data() {
        return {
            DsStext: '',
            showCountText: "",
            info: {

            },
            dialogVisible: false,
            timer: null, //计时器
            code: "", //验证码
            count: "", //倒计时
            show: true, //控制按钮
            registerFlag: true,
            innerVisible: false,
            flag: true,
            visible: false,
            registerForm: {
                phone: "",
                msg_code: "",
            },
            registerRules: {
                phone: [
                    { required: true, message: "请输入手机号", trigger: "change" },
                    { min: 11, message: "请输入正确的手机号", trigger: "blur" },
                ],
                msg_code: [
                    { required: true, message: "请输入验证码", trigger: "change" },
                ],
            },
            textarea: "",
            videoSrc: "",
            dp: null,
            options: {
                video: { url: "", type: "mp4" },
            },
            danmus: [],
            textList: [],
            numList: [1, 10, 66, 188, 520, 999, 1314],
            imgList: [],
            lodingFlag: true,
            interval: null,
            giftsFlag: '',
        }
    },
    mounted() {
        this.getVideoInfo()
        this.getDanmu()
        this.getGift()
         this.interval = setInterval(() => {
             this.getDanmu();
         }, 2500); // 每两秒调用一次 getDanmu 方法

    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    computed: {
        ...mapState(['nickname'])
    },
    methods: {
        async getVideoInfo() {
            const [err, res] = await this.$http.post("web/video/detail", {
                video_id: Number(this.$route.query.id),
            });
            if (err) {
                return;
            }
            this.updateVideoSource(res.url)
            this.info = res
        },
        async getGift() {
            const [err, res] = await this.$http.post("web/video/getGift");
            if (err) {
                return;
            }
            this.imgList = res
        },
        concern() {
            if (this.$store.state.login_token == "") {
                this.dialogVisible = true;
                this.registerFlag = true;
            } else {
                if (this.flag) {

                }
                this.flag = !this.flag
            }
        },
        openInint() {
            this.$refs.registerForm.resetFields();
        },
        updateVideoSource(newSrc) {
            this.dp = new DPlayer({
                container: document.getElementById("dplayer"),  // 播放器容器元素
                video: {  // 视频信息，更多可以参考官网：https://dplayer.diygod.dev/zh/guide.html
                    url: newSrc,  // 视频地址
                },
            });
            /*             this.dp.on('play', () => {
                        }); */
        },
        // 登录
        async Login() {
            this.$refs.registerForm.validate(async (v, i) => {
                if (v) {
                    const { registerForm } = this;
                    const [err, res] = await this.$http.post("web/login/login", {
                        ...registerForm,
                    });
                    if (err) {
                        return;
                    }
                    this.$store.commit("CHANGE_LOGIN_TOKEN", res.login_token);
                    this.$store.commit("CHANGE_NICK_NAME", res.nickname);
                    this.$store.commit("CHANGE_USER_ID", res.id);
                    this.$store.commit("CHANGE_AVATAR", res.avatar);
                    this.$store.commit("CHANGE_VIP", res.vip);
                    this.dialogVisible = false;
                    localStorage.setItem("stateInfo", JSON.stringify(this.$store.state));
                    this.$message({
                        message: "登陆成功",
                        type: "success",
                    });
                    window.location.reload();
                }
            });
        },
        // 发送验证码
        sendcode() {
            this.$refs.registerForm.validateField("phone", async (error) => {
                if (!error) {
                    this.innerVisible = true;
                    //请求
                } else {
                    return false;
                }
            });
        },
        goAgreement() {
            this.registerForm = {
                phone: "",
                msg_code: "",
            };
            this.dialogVisible = false;
            this.$router.push("/user_text");
        },
        goConceal() {
            this.registerForm = {
                phone: "",
                msg_code: "",
            };
            this.dialogVisible = false;
            this.$router.push("/conceal");
        },
        onSuccess() {
            // this.innerVisible = false
            this.slideLoading = true;
            setTimeout(async () => {
                setTimeout(() => {
                    this.slideLoading = false;
                    this.innerVisible = false;
                }, 2000);
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer); // 清除定时器
                            this.timer = null;
                        }
                    }, 1000);
                }
                const [err, res] = await this.$http.post("web/tools/sendSMS", {
                    phone: this.registerForm.phone,
                });
                if (err) {
                    return;
                }
            }, 2000);
        },
        onFail() {
            this.msg = "";
        },
        onRefresh() {
            this.msg = "";
        },
        end() {
        },
        async pushDanmus() {
            if (this.textarea == '') {
                this.$message.error("请填写发送内容");
                return
            }
            this.danmus.push(`${this.textarea}`)
            this.textList.push({ nickname: this.nickname, textarea: this.textarea })
            const [err, res] = await this.$http.post("web/video/sendDanmu", {
                video_id: Number(this.$route.query.id),
                id: this.$store.state.user_id,
                token: this.$store.state.login_token,
                content: this.textarea
            });
            if (err) {
                return;
            }
            this.textarea = ""
            this.getDanmu()
            this.$nextTick(() => {
                this.scrollToBottom()
            })
        },
        async getDanmu() {
            const [err, res] = await this.$http.post("web/video/getDanmu", {
                video_id: Number(this.$route.query.id),
                id: this.$store.state.user_id,
                token: this.$store.state.login_token,
                content: this.textarea
            });
            if (err) {
                return;
            }
            if (this.lodingFlag) {
                if (res.length) {
                    res.reverse().forEach(item => {
                        this.textList.push({ nickname: item.user_nickname, textarea: item.content, icon: item.gift_img ? item.gift_img : "",num:item.gift_num?item.gift_num:"" })
                    });
                }
            } else {
                if (res.length) {
                    this.textList = []
                    res.reverse().forEach(item => {
                        this.textList.push({ nickname: item.user_nickname, textarea: item.content, icon: item.gift_img ? item.gift_img : "",num:item.gift_num?item.gift_num:"" })
                    });
                }
            }
            this.lodingFlag = false
            this.DanmuList = res
            this.$nextTick(() => {
                this.scrollToBottom()
            })
        },
        showDsSText(item) {
            this.DsStext = 'DsStext' + item
        },
        showCount(index, gold = "") {
            this.showCountText = 'showCountText_' + index
            this.giftsFlag = gold
        },
        async rewardMoney(num, gold, icon, id) {
            if (this.$store.state.login_token == "") {
                this.dialogVisible = true;
                this.registerFlag = true;
            } else {
                const [err, res] = await this.$http.post("web/video/sendGift", {
                    video_id: Number(this.$route.query.id),
                    id: this.$store.state.user_id,
                    token: this.$store.state.login_token,
                    num: num,
                    gift_id: id,
                });
                if (err) {
                    return;
                }
                this.textList.push({ nickname: this.nickname, num, icon })
                this.danmus.push(`${this.nickname}送出了<img src="${icon}" alt="image" style="height: 30px;">× ${num}`)
                this.$nextTick(() => {
                    this.scrollToBottom()
                })
            }
        },
        scrollToBottom() {
            const content = this.$refs.content;
            content.scrollTop = content.scrollHeight;
        },
    },
}
</script>

<style lang="less" scoped>
/deep/.el-popover {
    background: rgb(37, 38, 50);
    border: 1px solid rgb(37, 38, 50);
    color: #fff;
}

.zbj_box {
    padding: 20px 0;
    display: flex;
    color: #fff;

    .left_box {
        width: 1200px;
        height: 700px;
        background: #252632;
        border-radius: 10px;

        #dplayer {
            height: 540px;
        }

        .video_box {
            position: relative;
        }

        .myDanmus {
            position: absolute;
            top: 0;
            pointer-events: none;
        }
    }

    .right_box {
        width: 300px;
        height: 700px;
        background: #252632;
        border-radius: 10px;
        position: relative;
        margin-left: 20px;

        .gz {
            padding: 20px;
            border-bottom: 1px solid #ffffff08;
        }

        #chat-room {
            padding: 10px;
            font-size: 14px;

            .content {
                height: 550px;
                overflow-y: scroll;
                /* 保持Y轴滚动功能 */
                scrollbar-width: none;
                /* Firefox */
                -ms-overflow-style: none;
                /* Internet Explorer 10+ */
            }

            .content::-webkit-scrollbar {
                display: none;
                /* Chrome, Safari 和 Opera */
            }

            .my-input {
                height: 50px;
                width: 240px;
                background: rgba(51, 52, 63, 1);
                color: rgba(255, 255, 255, .9);
                border-radius: 6px 4px 4px 6px;
                box-sizing: border-box;
                overflow: auto;
                resize: none;
                border: none;
                outline: none;
                display: block;
            }

            .my-input::-webkit-scrollbar {
                width: 5px;
            }

            /* 定制滚动轨道 */
            .my-input::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 10px;
            }

            /* 定制滚动滑块 */
            .my-input::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 10px;
            }

            /* 滑块悬停效果 */
            .my-input::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }

    .top_box {
        display: flex;
        padding: 15px;

        .user_img {
            border-radius: 50%;
            width: 40px;
            height: 40px;
        }
    }
}


.dz {
    font-size: 12px;
    color: rgba(255, 255, 255, .34);
}

.my-custom-button {
    background-color: #fe2c55;
    /* 自定义颜色 */
    border: #fe2c55;
    color: white;
    margin-left: 14px;
    margin-top: 5px;
}

.reward_list {
    padding: 15px 20px;
}

.gift-container {
    position: relative;
    display: inline-block;
}

/* 鼠标悬停在容器上时，触发图片动画和显示count-window */
.gift-container:hover .img-container {
    transform: translateY(-10px);
    /* 向上移动图片 */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    /* 增大阴影 */
}

.gift-container:hover .count-window {
    display: block;
}

.count-window {
    width: 292px;
    position: absolute;
    bottom: 100%;
    /* 将窗口显示在图片上方 */
    left: 50%;
    /* 水平居中 */
    transform: translateX(-50%);
    /* 配合left使用，确保准确居中 */
    background-color: #252632;
    color: #fff;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 10px;
    white-space: nowrap;
    /* 防止文本折行 */
    /* 其他样式根据需要添加 */
}

.num_list {
    width: 292px;
    flex-wrap: wrap;
    display: flex;
}

.num_list>div:nth-child(4) {
    margin-right: 0;
}

.num_list>div:nth-child(n+5) {
    margin-top: 12px;
}

.DsSiFD5E {
    margin: 0 12px 0 0;
    position: relative;
    width: 64px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #2e2f3b;
    color: #ffffffb3;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    /* 添加颜色变化动画 */
    &:hover {
        background-color: #FE2C55;
        /* 设置鼠标悬停时的背景颜色 */
        color: #FFF;
        /* 设置鼠标悬停时的字体颜色 */
    }
}

/* 图片的基本样式 */
.img-container {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* 平滑过渡效果 */
    transform: translateY(0);
    /* 默认位置 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-right: 15px;
    text-align: center;

    /* 默认阴影 */
    div {
        text-align: center;
        font-size: 10px;
    }
}
</style>